import React, { Fragment } from "react";
import storesData from "../../data/actionpulse/stores.json";

const SideBar = ({ sidebarIsOpen, handleSidebar }) => {
  return (
    <Fragment>
      <div className="sidebar-menu-wrapper">
        <div
          id="sidebar-menu"
          className={`sidebar-menu ${sidebarIsOpen ? "active" : null}`}
        >
          <div className="dismiss text-right mb-60 clearfix">
            <span className="close-btn" onClick={handleSidebar}>
              <i className="flaticon-cancel-music"></i>
            </span>
          </div>

          <div className="btns-group ul-li mb-60 clearfix">
            
            <ul className="clearfix">
              <li>
              <a
              rel="noopener noreferrer"
              href={storesData.appleStore}
              target="_blank"
              className="store-btn bg-default-blue"
            >
              <span className="icon">
                <i className="flaticon-apple"></i>
              </span>
              <strong className="title-text">
                <small>available on</small>
                apple store
              </strong>
            </a>
                
              </li>
              <li>
              <a
              rel="noopener noreferrer"
              href={storesData.googlePlay}
              target="_blank"
              className="store-btn bg-default-pink"
            >
              <span className="icon">
                <i className="flaticon-google-play"></i>
              </span>
              <strong className="title-text">
                <small>available on</small>
                google play
              </strong>
            </a>
              </li>
            </ul>
          </div>

          

          

          
        </div>
        <div
          onClick={handleSidebar}
          className={`overlay ${sidebarIsOpen ? "active" : null}`}
        ></div>
      </div>
    </Fragment>
  );
};

export default SideBar;
