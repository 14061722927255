import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import featuresData from "../../data/actionpulse/features.json";

const featureFour = () => {
  return (
    <Fragment>
      <section
        id="features-section"
        className="features-section sec-ptb-160 clearfix"
      >
        <Container>
          <div className="feature-item">
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="6" md="8">
                <div className="feature-content">
                  <div className="section-title mb-60">
                    <h2 className="title-text mb-0">
                      {featuresData[0].title}
                    <br /><span>Your Ultimate Habit Tracker</span>
                    </h2>
                  </div>

                  <p className="mb-30">{featuresData[0].paragraphs[0]}</p>
                  <p className="mb-60">{featuresData[0].paragraphs[1]}</p>

                  
                </div>
              </Col>

              <Col lg="5" md="5">
                <div className="feature-image-3">
                  <span
                    className="bg-image aos-init aos-animate"
                    data-aos="zoom-in"
                    data-aos-delay="100"
                  >
                    <img
                      src="assets/images/actionpulse/4-img-1.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="phone-image aos-init aos-animate"
                    data-aos="zoom-out"
                    data-aos-delay="300"
                  >
                    <img
                      src="assets/images/actionpulse/4-img-6.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="man-image-1 aos-init aos-animate"
                    data-aos="fade-right"
                    data-aos-delay="600"
                  >
                    <img
                      src="assets/images/actionpulse/4-img-3.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="man-image-2 aos-init aos-animate"
                    data-aos="fade-left"
                    data-aos-delay="700"
                  >
                    <img
                      src="assets/images/actionpulse/4-img-4.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="comment-image aos-init aos-animate"
                    data-aos="fade-down"
                    data-aos-delay="1000"
                  >
                    <img
                      src="assets/images/actionpulse/4-img-5.png"
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>
            </Row>
          </div>

          <div className="feature-item">
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="5" md="5">
                <div className="feature-image-3">
                  <span
                    className="bg-image aos-init aos-animate"
                    data-aos="zoom-in"
                    data-aos-delay="100"
                  >
                    <img
                      src="assets/images/actionpulse/4-img-2.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="phone-image aos-init aos-animate"
                    data-aos="zoom-out"
                    data-aos-delay="300"
                  >
                    <img
                      src="assets/images/actionpulse/5-img-1.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="man-image-1 aos-init aos-animate"
                    data-aos="fade-right"
                    data-aos-delay="600"
                  >
                    <img
                      src="assets/images/actionpulse/5-img-2.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="man-image-2 aos-init"
                    data-aos="fade-left"
                    data-aos-delay="700"
                  >
                    <img
                      src="assets/images/actionpulse/5-img-3.png"
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>

              <Col lg="6" md="8">
                <div className="feature-content">
                  
                      <div className="section-title mb-30">
                        <h2 className="title-text mb-0">
                          {featuresData[1].title}
                        </h2>
                      </div>
                    

                  
                      <p className="mb-30">
                        {featuresData[1].paragraphs[0]}
                  </p>
                  <p className="mb-30">
                        {featuresData[1].paragraphs[1]}
                  </p>
                  <p className="mb-30">
                        {featuresData[1].paragraphs[2]}
                      </p>
                    

                  <div className="info-list ul-li-block mb-45 clearfix">
                    
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </Fragment>
  );
};

export default featureFour;
